// DisclaimerPage.js
import React from 'react';

function DisclaimerPage() {
    return (
        <div style={{padding:'30px'}}>
            <h1>Disclaimer</h1>
            <p>Updated at 2023-11-22</p>
            <p>JodiSure hereby grants you access to the Website and invites you to purchase the services offered here.</p>

            <h2>Definitions and key terms</h2>
            To help explain things as clearly as possible in this Disclaimer, every time any of these terms are referenced, are strictly defined as:
            <ul>
                <li>Cookie: small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, remember information about you such as your language preference or login information.</li>
                <li>Company: when this policy mentions “Company,” “we,” “us,” or “our,” it refers to JodiSure, that is responsible for your information under this Disclaimer.</li>
                <li>Service: refers to the service provided by JodiSure as described in the relative terms (if available) and on this platform.</li>
                <li> Website: JodiSure."’s" site, which can be accessed via this URL:</li>
                <li>You: a person or entity that is registered with JodiSure to use the Services.</li>
            </ul>
            
            <h2>Limited liability</h2>
            <p>

            JodiSure endeavours to update and/or supplement the content of the app on a regular basis. Despite our care and attention, content may be incomplete and/or incorrect.

            The materials offered on the app are offered without any form of guarantee or claim to their correctness. These materials can be changed at any time without prior notice from JodiSure.

            Particularly, all prices on the app are stated subject to typing and programming errors. No liability is assumed for the implications of such errors. No agreement is concluded on the basis of such errors.

            JodiSure shall not bear any liability for hyperlinks to websites or services of third parties included on the app. From our app, you can visit other websites by following hyperlinks to such external sites. While we strive to provide only quality links to useful and ethical websites, we have no control over the content and nature of these sites. These links to other websites do not imply a recommendation for all the content found on these sites. Site owners and content may change without notice and may occur before we have the opportunity to remove a link which may have gone ‘bad’.

            Please be also aware that when you leave our app, other sites may have different privacy policies and terms which are beyond our control. Please be sure to check the Privacy Policies of these sites as well as their "Terms of Service" before engaging in any business or uploading any information.
            </p>

            <h2>Links to Other Websites Disclaimer</h2>
            This Disclaimer applies only to the Services. The Services may contain links to other websites not operated or controlled by JodiSure. We are not responsible for the content, accuracy or opinions expressed in such websites, and such websites are not investigated, monitored or checked for accuracy or completeness by us. Please remember that when you use a link to go from the Services to another website, our Privacy Policy is no longer in effect. Your browsing and interaction on any other website, including those that have a link on our platform, is subject to that website’s own rules and policies. Such third parties may use their own cookies or other methods to collect information about you. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy and Terms of every site You visit.

            We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.

            <h2>Errors and Omissions Disclaimer</h2>
            <p>JodiSure is not responsible for any content, code or any other imprecision.

            JodiSure does not provide warranties or guarantees.

            In no event shall JodiSure be liable for any special, direct, indirect, consequential, or incidental damages or any damages whatsoever, whether in an action of contract, negligence or other tort, arising out of or in connection with the use of the Service or the contents of the Service. JodiSure reserves the right to make additions, deletions, or modifications to the contents on the Service at any time without prior notice.</p>

            <h2>General Disclaimer</h2>
            The JodiSure Service and its contents are provided "as is" and "as available" without any warranty or representations of any kind, whether express or implied. JodiSure is a distributor and not a publisher of the content supplied by third parties; as such, JodiSure exercises no editorial control over such content and makes no warranty or representation as to the accuracy, reliability or currency of any information, content, service or merchandise provided through or accessible via the JodiSure Service. Without limiting the foregoing, JodiSure specifically disclaims all warranties and representations in any content transmitted on or in connection with the JodiSure Service or on sites that may appear as links on the JodiSure Service, or in the products provided as a part of, or otherwise in connection with, the JodiSure Service, including without limitation any warranties of merchantability, fitness for a particular purpose or non-infringement of third party rights. No oral advice or written information given by JodiSure or any of its affiliates, employees, officers, directors, agents, or the like will create a warranty. Price and availability information is subject to change without notice. Without limiting the foregoing, JodiSure does not warrant that the JodiSure Service will be uninterrupted, uncorrupted, timely, or error-free.

            <h2>Affiliate Links Disclosure</h2>
            JodiSure has affiliate links and in this section of the Disclaimer we will address how we use those affiliate links from other websites/companies and products. These “affiliate links” are specific URLs that contain the affiliate's ID or username.

            In compliance with the FTC guidelines, please assume the following about links and posts on this site:

            -Any/all of the links on JodiSure are affiliate links of which we receive a small commission from sales of certain items, but the price is the same for you. As JodiSure has grown, so have costs associated with running and maintaining it, and affiliate links are a way we help offset these costs.

            -If we post an affiliate link to a product, it is something that we personally use, support and would recommend without an affiliate link.

            Unless otherwise noted, all reviews are of items we have purchased and we are not paid or compensated in any way.

            <h2>Educational Disclosure</h2>
            Any Information provided by JodiSure is for educational purposes only, and is not to be interpreted as a recommendation for a specific treatment plan, product, or course of action. JodiSure is a distributor and not a publisher of the content supplied by third parties; as such, JodiSure exercises no editorial control over such content and makes no warranty or representation as to the accuracy, reliability or currency of any information or educational content provided through or accessible via JodiSure. Without limiting the foregoing, JodiSure specifically disclaims all warranties and representations in any content transmitted on or in connection with JodiSure or on sites that may appear as links on JodiSure, or in the products provided as a part of, or otherwise in connection with, the JodiSure. No oral advice or written information given by JodiSure or any of its affiliates, employees, officers, directors, agents, or the like will create a warranty.

            <h2>Testimonials Disclosure</h2>
            Any testimonials provided on this platform are opinions of those providing them. The information provided in the testimonials is not to be relied upon to predict results in your specific situation. The results you experience will be dependent on many factors including but not limited to your level of personal responsibility, commitment, and abilities, in addition to those factors that you and/or JodiSure may not be able to anticipate.

            We will give honest testimonials to our visitors regardless of any discount. Any product or service that we test are individual experiences, reflecting real life experiences. The testimonials could be displayed on audio, text or video and are not necessarily representative of all of those who will use our products and/or services.

            JodiSure does not guarantee the same results as the testimonials given on our platform. Testimonials presented on JodiSure are applicable to the individuals writing them, and may not be indicative of future success of any other individuals.

            Please don’t hesitate to contact us if you would like to know more about testimonials, discounts, or any of the products/services that we review.

            <h2>Your Consent</h2>
            We've updated our Disclaimer to provide you with complete transparency into what is being set when you visit our site and how it's being used. By using our app, registering an account, or making a purchase, you hereby consent to our Disclaimer and agree to its terms.

            <h2>Changes To Our Disclaimer</h2>
            Should we update, amend or make any changes to this document so that they accurately reflect our Service and policies. Unless otherwise required by law, those changes will be prominently posted here. Then, if you continue to use the Service, you will be bound by the updated Disclaimer. If you do not want to agree to this or any updated Disclaimer, you can delete your account.
            <h2>Contact Us
            </h2>
            <p>Please don’t hesitate to contact us if you have any questions.</p>
            <p>Via Email: <a href="mailto:admin@jodisure.com">admin@jodisure.com</a></p>
        </div>
    );
}

export default DisclaimerPage;
