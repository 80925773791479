import React from 'react'

const DeleteProfile = () => {
  return (
    <div style={{padding:'40px'}}>
        <div id="root"><div class="container mx-auto mb-5 font-Barlow"><h1 class="text-2xl text-center font-bold m-4">How to Delete Profile</h1><p class="mt-4 font-bold">IN ORDER TO DELETE YOUR PROFILE MAIL US WITH YOUR REGISTERED MAIL ID OR WHATTSAPP US WITH YOUR REGISTERED PHONE NUMBER MENTIONING YOUR USER ID AND FULL NAME </p><strong class="text-2xl">Contact Us </strong><p>Don't hesitate to contact us if you have any questions regarding this Disclaimer.</p><li>Via Email: admin@jodisure.com</li></div></div>
    </div>
  )
}

export default DeleteProfile